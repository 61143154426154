const head = {
    state: {
        styletype: ''
    },
    mutations: {
        edit_styletype(state,e) {
            state.styletype = e
        }
    }
}

export default head