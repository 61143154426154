import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// import "amfe-flexible/index.js";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import "./permission";

// 将自动注册所有组件为全局组件
import dataV from "@jiaminghi/data-view";
import "@vuemap/vue-amap/dist/style.css";
import ModeHeadTitle from "@/components/modeheadtitle/modeheadtitle.vue";
import customed from "@/theme/echarts/customed";
import aheader from "@/components/head/aheader.vue";
import MenuComs from "@/components/menucoms/menucoms";
import * as echarts from "echarts";
Vue.prototype.echarts = echarts;
Vue.prototype.customed = customed;
import scroll from "vue-seamless-scroll";
import gdmap from "@/components/gdmap/gdmap";
import CommunityMap from "@/components/CommunityMap/CommunityMap";
import aruitab from "@/components/Arui/aruitab.vue";

import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)

import mintitle from "@/components/Arui/mintitle.vue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
import Vue2OrgTree from "vue2-org-tree";
import "vue2-org-tree/dist/style.css";

Vue.use(Vue2OrgTree);
Vue.component("MenuComs", MenuComs); //全局挂载组件
Vue.component("ModeHeadTitle", ModeHeadTitle); //全局挂载组件
Vue.component("aheader", aheader); //全局挂载组件
Vue.component("gdmap", gdmap); //全局挂载组件
Vue.component("CommunityMap", CommunityMap);
Vue.component("aruitab", aruitab); //全局挂载组件
Vue.component("mintitle", mintitle); //全局挂载组件

import VueAMap from "@vuemap/vue-amap";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "7a19317479ec28f69447e9a0700ebfcc",
  // v: "1.4.9",
});

Vue.use(videoPlayer);
Vue.use(dataV);
Vue.use(ElementUI);
Vue.use(scroll);

Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
