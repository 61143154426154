<style lang="less">
.muddleheaded {
  align-items: center;
  height: 44px;
  width: 220px;
  //background-image: url("@/assets/comimg/comtitle-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 22px;
  font-weight: 800;
  padding: 7px 0 7px 18px;

  .title-icon-style {
    width: 32px;
    height: 32px;
    display: inline-block;
    //background: url("../../assets/modicon/ar-title-icon-img.png") no-repeat center 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.ar-cent-bg {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 16px;
}

.cent-padding {
}
.flex-box {
  display: flex;
}
.btn-box {
  margin-left: -4px;
  display: flex;
  align-items: flex-end;
  span {
    display: inline-block;
    border: 1px solid #205dcb;
    padding: 4px 12px;
    margin-left: 10px;
  }
}
.absoluteClass {
  position: absolute;
}
.active {
  background-color: #0487ea;
}
</style>
<template>
  <div
    :style="{
      backgroundImage: `url(${require(`@/assets/comimg/itemboxbg/${bgimg}`)})`,
    }"
    class="ar-cent-bg"
  >
    <!--    <dv-border-box-8 :dur="10">-->
    <div class="flex-box" v-if="title">
      <div
        v-bind:class="{ absoluteClass: absolute }"
        class="muddleheaded"
        :style="{
          backgroundImage: `url(${require('@/assets/comimg/' + titlebg)})`,
        }"
      >
        <span>{{ title }}</span>
      </div>
      <div class="btn-box" v-if="list">
        <span
          @click="_btnFun(item)"
          class="sbs"
          :class="{ active: btnIndex == item.value }"
          :key="index"
          v-for="(item, index) in list"
          >{{ item.label }}</span
        >
      </div>
    </div>
    <div class="cent-padding">
      <slot></slot>
    </div>
    <!--    </dv-border-box-8>-->
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
export default {
  // import 引入的组件需要注入到对象中才能使用
  name: "modeheadtitle",
  props: {
    absolute: false,
    btnFun: {
      type: Function,
      default: function () {},
    },
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "ar-title-icon-img.png",
    },
    bgimg: {
      type: String,
      default: "item-box-bg1.png",
    },
    titlebg: {
      type: String,
      default: "comtitle-bg.png",
    },
  },
  // 这里存放数据
  data() {
    return {
      btnIndex: "",
    };
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {},
  // 方法集合
  methods: {
    _btnFun(item) {
      this.btnIndex = item.value;
      this.btnFun(item);
    },
  },
};
</script>
