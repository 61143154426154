const arfun = {
    getNowDate: function () {
        var myDate = new Date;
        var year = myDate.getFullYear(); //获取当前年
        var mon = myDate.getMonth() + 1; //获取当前月
        var date = myDate.getDate(); //获取当前日
        var hours = myDate.getHours(); //获取当前小时
        var minutes = myDate.getMinutes(); //获取当前分钟
        var seconds = myDate.getSeconds(); //获取当前秒
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        var now = year + "-" + mon + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
        return now;
    },

    /*
    * 判断一个变量是否为空
    * 参数1：变量本身
    * 参数2：如果变量本身为空时，返回那种类型的空数据
    *
    * */
    isEmpty: (data, type) => {
        if (data == null) {
            return type
        }
    }
}

export default arfun
