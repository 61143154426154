import axios from 'axios'; // 引入axios
import { Message, MessageBox } from 'element-ui';

// 是否显示重新登录
export let isRelogin = { show: false };
// post请求头
axios.defaults.headers['Content-Type'] = 'multipart/form-data;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '/slzdpapi',
    // 超时
    timeout: 60000
})
// 添加请求拦截器
service.interceptors.request.use(config => {

    // 是否需要设置token
    const isToken = (config.headers || {}).isToken === false
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (!isToken) {
        config.headers = {
            token: localStorage.getItem('token'),
            organ: localStorage.getItem('organ_id'),
            worker: localStorage.getItem('worker_id'),
        }
    }
    // 在发送请求之前做些什么
    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    let resdata = response.data

    let Ttoken = localStorage.getItem('token')

    // 如果接口成功返回
    if (resdata.code == 200 || resdata.code == 1) {
        return resdata
    } else if (resdata.code == 401) {
        if (!isRelogin.show) {
            isRelogin.show = true;
            MessageBox.confirm('登录失效，请重新登录', '系统提示', {
                confirmButtonText: '重新登录',
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showCancelButton: false,
                showClose: false,
                type: 'warning'
            }).then((res) => {
                location.href = '/login';
                isRelogin.show = false;
            }).catch(() => {
                isRelogin.show = false;
            })
        }
        return resdata;
    } else {
        Message({
            message: resdata.msg,
            type: 'error'
        })
        isRelogin.show = false;
        return Promise.reject(resdata);
    }

}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default service
