<template>
    <div class="wrapper">
        <div ref="box" class="my-inbox" @click.prevent="">
            <div v-for="(item, index) in menuItems" :id="index" :key="index" :style="getStyle(item.url == currentIndex)"
                class="item" @click.prevent="clickMenuItem(index, $event, item.type)">
                <i :style="{ backgroundImage: `url(${item.image})` }" class="aruitabicon"></i>
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lz-menu',
    props: {
        DefaultIndex: '',
        iColor: {
            type: String, //未选中文本颜色
            default: '#19ECFF',
        },
        iBgColor: {
            //未选中背景色
            type: String,
            default: '#2B4C78',
        },
        iActiveColor: {
            //选中时文本颜色
            type: String,
            default: '#fff',
        },
        iActiveBgColor: {
            //选中时背景色
            type: String,
            default: '#5a9ae8',
        },
    },
    data() {
        return {
            currentIndex: '',
            menuItems: null,
        };
    },
    beforeMount() {
        //挂着前，修改默认选项
        this.currentIndex = this.DefaultIndex;
        this.menuItems = JSON.parse(localStorage.getItem('routerslist'))
    },

    methods: {
        getStyle(select) {
            return {
                color: select ? this.iActiveColor : this.iColor,
                background: select ? this.iActiveBgColor : this.iBgColor,
            };
        },
        clickMenuItem(idx, event, type) {
            let inbox = this.$refs.box;
            // 点击事件传递给调用者
            if (this.currentIndex != this.menuItems[idx].url) {
                this.currentIndex = this.menuItems[idx].url;
                if (type == 2) {
                    window.open(this.menuItems[idx].url, '_blank')
                } else {
                    this.$emit('clickLzMenuItem', this.menuItems[idx].url);
                }
            }
            //滚动 到中间
            let e = event.currentTarget;
            let left = e.offsetLeft - inbox.clientWidth / 2 + e.offsetWidth / 2;
            inbox.scrollLeft = left;
        },
    },
};
</script>

<style lang="scss" scoped>
.aruitabicon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;

}

.wrapper {
    width: 100%;

    .my-inbox {
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        white-space: nowrap;
        display: flex;

        .item {
            // width: 170px;
            height: 40px;
            background: #2B4C78;
            border: 1px solid #5A9AE8;
            border-radius: 4px;
            display: inline-block;
            //height: 2rem;
            line-height: 2rem;
            margin: 0 0.5rem;
            padding: 5px 0.8rem;
            text-align: center;
            font-size: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .active {
            background: #5a9ae8;
            border: 1px solid #5a9ae8;
        }
    }
}
</style>
