<template>
	<div class="layout-box">
		<ScaleBox :width="1920" :height="1080" bgc="transparent" :delay="1" :isFlat="true">
			<newheader></newheader>
			<router-view />
		</ScaleBox>
	</div>
</template>

<script>
import newheader from './components/newheader';
import ScaleBox from 'vue2-scale-box';

export default {
	components: {
		newheader,
		ScaleBox,
	},
	name: 'layout',
};
</script>

<style lang="scss" scoped>
.layout-box {
	height: 100%;
	width: 100%;
	background-color: #01224f;
	//margin: 0 auto;
}

// .Record-varchar {
//   text-align: center;
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   width: 100%;
// }
</style>
