<style lang="less">
#header-box {
  background: url("@/assets/head/head-bg.png") no-repeat center / 100% 100%;
  height: 98px;
  width: 100%;
  padding: 0 47px 0 0px;

  .title-text-style {
    font-size: 36px;
    font-weight: 800;
    font-family: PingFang SC-Heavy, PingFang SC;
  }

  .header-Flex-box {
    width: 733px;
    align-items: center;

    .header-flex-cent {
      width: 60px;
      text-align: center;
    }
  }
}

.right-setting-bg {
  background-image: url('@/assets/head/title-Settings-bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 144px;
  height: 44px;
}

.arui-mrg-top {
  margin-top: 30px;
}

.rightfgx-style {
  width: 2px;
  height: 48px;
  background: #5A9AE8;
  opacity: 1;
  margin: 0 55px 0 23px;
}

.cllbankhomestyle {
  background-image: url("@/assets/head/cllbank-home-icon.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 60px;
  width: 60px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.title-text-style {
  .title-text-style-cent {
    letter-spacing: 8px;
  }

  .title-text-style-weather {
    font-size: 16px;
    font-weight: 400;
    text-align: center;

    .title-text-style-weather-cent {
      position: fixed;
      top: 108px;
      right: 495px;
    }

    .title-text-style-weather-bbb {
      position: fixed;
      top: 108px;
      left: 495px;
    }
  }
}

.head-dropdown-style-box {
  align-items: center;
  height: 48px;
  margin-top: 33px;

  .head-dropdown-style-text {
    width: 916px;
  }
}

.rightfgx-style-box-dro {
  text-align: right;
  display: flex;
  justify-content: flex-end;

  .rightfgx-style-dropdown-dro {
    height: 44px;
  }
}

</style>
<template>
  <div id="header-box" class="arFlex">
    <div class="arFlex header-Flex-box" >
      <div class="cllbankhomestyle"  @click="navgetto(navtourl)">
        <!--                <el-button v-if="state !== 'login'" size="mini" @click="navgetto(navtourl)" type="primary"-->
        <!--                    icon="el-icon-s-home"></el-button>-->
        <!--        <i class="el-icon-s-home" style="font-size: 32px;color: #fff;cursor: pointer;" @click="navgetto(navtourl)"></i>-->
      </div>
      <div class="title-text-style">
        <div class="title-text-style-cent">
          <span>
          <slot></slot>
          </span>
        </div>
        <div class="title-text-style-weather">
          <div class="title-text-style-weather-cent">
            <span>泸州-</span>
            <span>{{ weather.text }}-</span>
            <span>{{ weather.temp }}℃</span>
          </div>
          <span class="title-text-style-weather-bbb">{{ bbb }}</span>
        </div>
      </div>
    </div>
    <div class="arFlex head-dropdown-style-box" >
      <div class="head-dropdown-style-text">
        <aruitab DefaultIndex="home" @clickLzMenuItem="clickMenuItem"></aruitab>
      </div>
      <div v-if="state !== 'login'" class="rightfgx-style"></div>
      <div class="rightfgx-style-box-dro">
        <!-- <span style="margin-right: 20px" class="arui-mrg-top">{{ bbb }}</span> -->
        <el-dropdown v-if="state !== 'login'" class="rightfgx-style-dropdown-dro" @command="handleCommand">
          <span class="el-dropdown-link right-setting-bg"> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">跳至后台</el-dropdown-item>
            <el-dropdown-item :command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import arfun from "@/utils/arcomjs";
import {getquery, saasinfo} from '@/request/api'
export default {
  // import 引入的组件需要注入到对象中才能使用
  name: "aheader",
  props: {
    navtourl: {
      type: String,
      default: '/'
    },
    state: {
      type: String,
      default: ''
    }
  },
  // 这里存放数据
  data() {
    return {
      bbb: arfun.getNowDate(),
      weather: {},
      userinfo: null
    };
  },
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    if (this.state !== 'login') {
      saasinfo().then(res => {
        this.userinfo = res.data
        localStorage.setItem('userinfo', JSON.stringify(res.data))
      })
    } else {

    }

    setInterval(() => {
      this.bbb = arfun.getNowDate();
    }, 1000);

    getquery().then(res => {
      this.weather = res.data
    })
  },
  // 方法集合
  methods: {
    handleCommand(e) {
      switch (e) {
        case 1:
          let token = localStorage.getItem("token");
          let workerid = localStorage.getItem("worker_id");
          let organid = localStorage.getItem("organ_id");

          window.open(
              `http://ht.jczsaas.com/?token=${token}&workerid=${workerid}&organid=${organid}`,
              "_blank"
          );

          // window.open(
          //     `http://localhost:80/?token=${token}&workerid=${workerid}&organid=${organid}`,
          //     "_blank"
          // );
          break;
        case 2:
          localStorage.clear()
          this.$router.replace({
            path: '/login'
          })
          break;
      }
    },
    navgetto(e) {
      this.$router.replace({
        path: e
      })
    },
    clickMenuItem(e) {
      this.$router.push({
        path: `/${e}`
      })
    },
  },
};
</script>
